import { SHARED_ACTIONS } from '../../constants/Actions/Shared';

// Estado inicial del reducer
const oInitialState = {
    bLoading: false,
    nLoading: 0,
    sMessage: '',
    sMessageType: '',
}
/**
 * Reducer que sera utilizado para controlar variables globales en el sistema, por ejemplo el loading.
 */
export const SharedReducer = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case SHARED_ACTIONS.MESSAGE:
            return { ...oState, bLoading: false, sMessage: oAction.sMessage, sMessageType: oAction.sMessageType };
        case SHARED_ACTIONS.CLEAN_MESSAGE:
            return oInitialState;
        case SHARED_ACTIONS.ACTIVE_LOADING:
            return { ...oState, bLoading: true };
        case SHARED_ACTIONS.DESACTIVE_LOADING:
            return { ...oState, bLoading: false };
        default:
            return oState;
    }
}
