import { CONTACT_ACTIONS } from 'constants/Actions/Contact';

// Estado inicial del reducer
const oInitialState = {
    bStatus: false,
}
/**
 * Reducer que sera utilizado para controlar los datos globales referidos a contactos.
 */
export const ContactReducers = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case CONTACT_ACTIONS.CONTACT_SEND:
            return { ...oState, bStatus: true };
        case CONTACT_ACTIONS.CONTACT_CLEAN:
            return { ...oState, bStatus: false };
        default:
            return oState;
    }
}
