import React, { Component } from 'react';
import { connect } from 'react-redux';
import ExemplaryCard from "components/exemplaries/ExemplaryCard";
import { Col, Row, Container } from "react-bootstrap";
import { actionCleanMares, actionGetMares } from 'redux/actions/ExemplaryActions';
import PaginationTable from 'components/shared/PaginationTable';
import 'assets/css/mares.css';
import { Helmet } from "react-helmet";
import Search from 'components/shared/Search';

class MaresPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nPage: 1,
      search: ""
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.props.get(this.state.nPage - 1, this.state.search);
  };

  componentWillUnmount = async () => {
    this.props.cleanMares();
  }

  componentDidUpdate = (oPrevProps) => { };

  handleNavigate = (oMare) => {
    this.props.history.push(`ejemplar/${oMare.id}`);
  };

  handleChangePage = async (nPage) => {
    await this.setState({ nPage });
    this.props.get(nPage - 1, this.state.search);
  }

  handleSearch = async (search) => {
    this.props.get(0, search);
    this.setState({ search, nPage: 1 });
  }

  render = () => {
    const { aMares, nTotalPages } = this.props.oExemplaries;
    const { nPage } = this.state;
    return (
      <Container>
        <Helmet>
          <title>Yeguas Madre | Haras Santa María de Juncal</title>
          <meta name="description" content="Yeguas madre del Haras Santa María de Juncal" />
        </Helmet>
        <Row className='container-title'>
          <Col md={6} sm={6} xs={12}>
            <h2 className='title'>Yeguas Madre</h2>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Search action={this.handleSearch} />
          </Col>
        </Row>
        <hr />
        {aMares && (
          <>
            <Row>
              {aMares.length > 0 ? (
                aMares.map((oMare, nIndex) => (
                  <Col lg={4} md={6} xs={12} key={`${oMare.id}_${oMare.name}_${nIndex}_page`}>
                    <ExemplaryCard handleAction={() => this.handleNavigate(oMare)} oExemplary={oMare} nCount={3}/>
                  </Col>
                ))
              ) : (
                <Col md={12} className="p-5 text-center">
                  <p>De momento no contamos con Yeguas Madre en exposición</p>
                </Col>
              )}
            </Row>
            <Row className="justify-center">
                <PaginationTable  nPage={nPage} nTotalPages={nTotalPages} fAction={this.handleChangePage}/>
            </Row>
          </>
        )}
      </Container>
    );
  };
}

const mapStateToProps = (oState) => ({
  oSession: oState.sessionReducer,
  oExemplaries: oState.ExemplaryReducers
});

const mapDispatchToProps = (dispatch) => ({
  get: (nPage, search) => {
    dispatch(actionGetMares(nPage, search));
  },
  cleanMares: () => {
    dispatch(actionCleanMares())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MaresPage);
