import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import IndexPage from 'pages/public/index/IndexPage';
import ExemplaryPage from 'pages/public/exemplary/ExemplaryPage';
import ProductionPage from 'pages/public/production/ProductionPage';
import HarasPage from 'pages/public/haras/HarasPage';
import NewsPage from 'pages/public/news/NewsPage';
import StallionsPage from 'pages/public/stallions/StallionsPage';
import MaresPage from 'pages/public/mares/MaresPage';
import NotFound from 'components/layout/NotFound';

const aRoutes = [
  {
    path: '/',
    exact: true,
    component: IndexPage,
  },
  {
    path: '/ejemplar/:nId',
    exact: true,
    component: ExemplaryPage,
  },
  {
    path: '/produccion',
    exact: true,
    component: ProductionPage,
  },
  {
    path: '/haras',
    exact: true,
    component: HarasPage
  },
  {
    path: '/noticias',
    exact: true,
    component: NewsPage
  },
  {
    path: '/yeguas',
    exact: true,
    component: MaresPage
  },
  {
    path: '/padrillos',
    exact: true,
    component: StallionsPage
  },
  {
    path: '*',
    exact: true,
    component: NotFound
  }
];

function RouteConfig(props) {
  return (
    <Switch>
      {
        aRoutes.map((oRouter, nIndex) =>
          <RouteWithSubRoutes key={nIndex} {...oRouter} />
        )
      }
    </Switch>
  );
}

// A special wrapper for <Route> that knows how to
// handle 'sub'-routes by passing them in a `routes`
// prop to the component it renders.
export function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) =>
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      }
    />
  );
}

const mapStateToProps = (state) => ({
  oSession: state.sessionReducer,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RouteConfig);
