import { CONTACT_ACTIONS } from 'constants/Actions/Contact';
import { CONSTANTS_MESSAGE } from 'constants/ConstantsMessages';
import { SendContact } from 'redux/services/ContactServices';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage } from './SharedActions';

export const actionSendContact = (sName, sEmail, sComment) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        SendContact(sName, sEmail, sComment).then(oSuccess => {
            dispatch(actionMessage(oSuccess.message, CONSTANTS_MESSAGE.TYPE_SUCCESS));
            dispatch(actionDispatch(CONTACT_ACTIONS.CONTACT_SEND));
        }).catch((oError) => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

export const actionCleanContact = () => {
    return dispatch => {
        dispatch(actionDispatch(CONTACT_ACTIONS.CONTACT_CLEAN));
    }
}