import { Constants } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";

const URL_PUBLIC_EXEMPLARIES = `${Constants.API_PUBLIC_URL}exemplaries/`;

/**
 * Servicio que toma las yeguas madre que serán mostradas en el Home
 */
export const GetExemplariesPerHome = () => {
    return oAxiosInstance.get(`${URL_PUBLIC_EXEMPLARIES}home`).then(oResult => oResult.data);
}

/**
 * Servicio que obtiene el exemplary por id
 */
 export const GetExemplary = (id) => {
    return oAxiosInstance.get(`${URL_PUBLIC_EXEMPLARIES}${id}}`).then(oResult => oResult.data);
}

/**
 * Servicio que obtiene los padrillos que se mostraran en la page Padrillos
 */
 export const GetStallions = (nPage, search) => {
    return oAxiosInstance.get(`${URL_PUBLIC_EXEMPLARIES}stallions?page=${nPage}&search=${search}`).then(oResult => oResult.data);
}

/**
 * Servicio que obtiene las madres que se mostraran en la page Yeguas madre
 */
 export const GetMares = (nPage, search) => {
    return oAxiosInstance.get(`${URL_PUBLIC_EXEMPLARIES}mares?page=${nPage}&search=${search}`).then(oResult => oResult.data);
}