
import oAxios from 'axios';
import promise from 'promise';
import { getTokenLocalStorange } from 'redux/services/StorageServices';
// import { actionLogout } from 'redux/actions/UserActions';
// import store from 'redux/Store';

const oAxiosInstance = oAxios.create();

oAxiosInstance.interceptors.request.use(async function (oConfig) {
    var sToken = await getTokenLocalStorange();
    if (sToken)
        if (oConfig.method !== 'OPTIONS')
            oConfig.headers.Authorization = sToken;
    return oConfig;
}, function (oError) {
    return promise.reject(oError);
});

oAxiosInstance.interceptors.response.use(oResponse => {
    return promise.resolve(oResponse);
}, oError => {
    // if (oError.response && oError.response.status === 401)
    //     store.dispatch(actionLogout());
    return promise.reject(oError.response);
});

export default oAxiosInstance;