import { EXEMPLARY_ACTIONS } from 'constants/Actions/Exemplary';
import { GetExemplariesPerHome, GetExemplary, GetMares, GetStallions } from 'redux/services/ExemplaryServices';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage } from './SharedActions';

export const actionGetExemplariesPerHome = () => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetExemplariesPerHome().then(oSuccess => {
            dispatch(actionDispatch(EXEMPLARY_ACTIONS.EXEMPLARIES_HOME, oSuccess.data));
        }).catch(oError => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

 export const actionGetStallions = (nPage, search) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetStallions(nPage, search).then(oSuccess => {
            dispatch(actionDispatch(EXEMPLARY_ACTIONS.STALLIONS, oSuccess));
        }).catch(oError => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

export const actionGetMares = (nPage, search) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetMares(nPage, search).then(oSuccess => {
            dispatch(actionDispatch(EXEMPLARY_ACTIONS.MARES, oSuccess));
        }).catch(oError => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

 export const actionGetExemplary = (id) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetExemplary(id).then(oSuccess => {
            if(!oSuccess.data[0])
                dispatch(actionMessage("No se encontro el ejemplar"));
            else
                dispatch(actionDispatch(EXEMPLARY_ACTIONS.EXEMPLARY, oSuccess.data[0]));
        }).catch(oError => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

 export const actionCleanExemplary = () => {
    return dispatch => dispatch(actionDispatch(EXEMPLARY_ACTIONS.EXEMPLARY_CLEAN));
}

export const actionCleanStallions = () => {
    return dispatch => dispatch(actionDispatch(EXEMPLARY_ACTIONS.STALLIONS_CLEAN));
}

export const actionCleanMares = () => {
    return dispatch => dispatch(actionDispatch(EXEMPLARY_ACTIONS.MARES_CLEAN));
}