// Constante del sistema que indica la version del mismo
export const APP_VERSION = 'v 0.1';
// Constantes del sistema que seran importadas en los demas archivos
export const Constants = {
    API_PUBLIC_URL: `${process.env.REACT_APP_API_URL}public/`,
    API_PRIVATE_URL: `${process.env.REACT_APP_API_URL}admin/`,
    API_EXEMPLARIES_DEFAULT: `${process.env.REACT_APP_API_URL}exemplaries/default.png`,
    API_COUNTRIES: `${process.env.REACT_APP_API_URL}countries/`,
    API_GOOGLE_MAP_ID: `harasweb`,
    API_GOOGLE_MAP_API_KEY: `AIzaSyD-iiCHddNdzK6UsFhLMobGdvtP-qBFFa8`,
}
