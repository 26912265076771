import React, { Component } from 'react';
import { connect } from 'react-redux';
import Logo from 'assets/logo.png';
import { Row, Col, Container } from 'react-bootstrap';
import './HarasPage.css';
import Map from 'components/shared/Map';
import { Helmet } from "react-helmet";

class HarasPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
  }

  componentDidUpdate = (oPrevProps) => { }

  render = () => {
    return (
      <Container style={{margin: "6rem auto 2rem auto"}}>
        <Helmet>
          <title>Haras | Haras Santa María de Juncal</title>
          <meta name="description" content="Información del Haras Santa María de Juncal" />
        </Helmet>
        <div className='text-center'>
          <img src={Logo} className='my-4' alt='Logo ' />
        </div>
        <Row>
          <Col md={12} xs={12} className='haras-map my-4'>
            <Map />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = oState => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HarasPage);