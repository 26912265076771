import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actionCleanStallions, actionGetStallions } from 'redux/actions/ExemplaryActions';
import ExemplaryCard from "components/exemplaries/ExemplaryCard";
import PaginationTable from 'components/shared/PaginationTable';
import { Col, Row, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Search from 'components/shared/Search';

class StallionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nPage: 1,
      search: ""
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    this.props.get(this.state.nPage - 1, this.state.search)
  };

  componentWillUnmount = async () => {
    this.props.cleanStallions();
  }

  componentDidUpdate = (oPrevProps) => { };

  handleNavigate = (oStallion) => {
    this.props.history.push(`ejemplar/${oStallion.id}`);
  };

  handleChangePage = async (nPage) => {
    await this.setState({ nPage });
    this.props.get(nPage - 1, this.state.search);
  }

  handleSearch = async (search) => {
    this.props.get(0, search);
    this.setState({ search, nPage: 1 });
  }

  render = () => {
    const { aStallions, nTotalPages } = this.props.oExemplaries;
    const { nPage } = this.state;
    return (
      <Container>
        <Helmet>
          <title>Padrillos | Haras Santa María de Juncal</title>
          <meta name="description" content="Padrillos del Haras Santa María de Juncal" />
        </Helmet>
        <Row className='container-title'>
          <Col md={6} sm={6} xs={12}>
            <h2 className='title'>Padrillos</h2>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Search action={this.handleSearch} />
          </Col>
        </Row>
        <hr />
        {aStallions && (
          <>
            <Row>
              {aStallions.length > 0 ? (
                aStallions.map((oStallion, nIndex) => (
                  <Col lg={4} md={4} xs={12} key={`${oStallion.id}_${oStallion.name}_${nIndex}_page`}>
                    <ExemplaryCard handleAction={() => this.handleNavigate(oStallion)} oExemplary={oStallion} nCount={3}/>
                  </Col>
                ))
              ) : (
                <Col md={12} className="p-5 text-center">
                  <p>De momento no contamos con Padrillos en exposición</p>
                </Col>
              )}
            </Row>
            <Row className="justify-center">
                <PaginationTable  nPage={nPage} nTotalPages={nTotalPages} fAction={this.handleChangePage}/>
            </Row>
          </>
        )}
      </Container>
    );
  };
}

const mapStateToProps = (oState) => ({
  oSession: oState.sessionReducer,
  oExemplaries: oState.ExemplaryReducers,
});

const mapDispatchToProps = (dispatch) => ({
  get: (nPage, search) => {
    dispatch(actionGetStallions(nPage, search))
  },
  cleanStallions: ()  => {
    dispatch(actionCleanStallions())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(StallionsPage);
