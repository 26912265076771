import { Constants } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";

const URL_PUBLIC_NOTICES = Constants.API_PUBLIC_URL + 'news/';

/**
 * Servicio que toma las noticias para la vista del Home
 */
export const GetNewsHome = () => {
    return oAxiosInstance.get(`${URL_PUBLIC_NOTICES}home`).then(oResult => oResult.data);
}

/**
 * Servicio que toma las noticias para la pagina
 */
 export const GetNews = (nPage) => {
    return oAxiosInstance.post(`${URL_PUBLIC_NOTICES}list`, {page: nPage}).then(oResult => oResult.data);
}