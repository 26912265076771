import React from 'react';
import { Pagination } from 'react-bootstrap';

export default function PaginationTable(oProps) {
    const { nPage, nTotalPages, fAction, sClassName = '' } = oProps;
    let nStartPage = nPage < 5 ? 1 : (nPage - 3),
        nCount = nStartPage,
        nFinalPage = nTotalPages > 7 && nPage < 5 ? 7 : (nPage + 3) < nTotalPages ? (nPage + 3) : nTotalPages,
        aPages = [];
    while (nCount <= nFinalPage) {
        if (nCount === nPage)
            aPages.push({ nPage: nCount, bIsActive: true });
        else
            aPages.push({ nPage: nCount, bIsActive: false });
        nCount++;
    }
    return (
        (nTotalPages && nTotalPages > 1) ?
            <div className={`${sClassName}`}>
                <Pagination size='md' bsPrefix='pagination' as={3}>
                    {
                        nStartPage > 1 &&
                        <>
                            <Pagination.First onClick={() => fAction(1)} />
                            <Pagination.Prev onClick={() => fAction((nPage - 1))} />
                        </>
                    }
                    {
                        aPages.map((oPage) => (
                            <Pagination.Item active={oPage.bIsActive} key={`page_${oPage.nPage}`} onClick={() => fAction(oPage.nPage)}>{oPage.nPage}</Pagination.Item>
                        ))
                    }
                    {
                        nFinalPage < nTotalPages &&
                        <>
                            <Pagination.Next onClick={() => fAction((nPage + 1))} />
                            <Pagination.Last onClick={() => fAction(nTotalPages)} />
                        </>
                    }
                </Pagination>
            </div> : null
    );
}
