import React from "react";
import "assets/css/production.css";
import { Table } from "react-bootstrap";
import { Constants } from "constants/Constants";
import { useHistory } from "react-router";

export default function ProductionTable(oProps) {
  const { aProduction } = oProps;
  const history = useHistory();
  
  const handleNavigate = (oBirth) => {
    history.push({pathname: `/ejemplar/${oBirth.id}`});
  }

  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Nombre</th>
            <th className="text-center">Padre</th>
            <th className="text-center">Madre</th>
            <th className="text-center">Abuelo Materno</th>
            <th className="text-center">País</th>
            <th className="text-center">Generación</th>
          </tr>
        </thead>
        <tbody>
          {
            aProduction && aProduction.length > 0 ?
              aProduction.map((oBirth) => (
                <tr key={`birht_row_${oBirth.id}`}>
                  <td className="production_name" onClick={() => handleNavigate(oBirth)}>{oBirth.name}</td>
                  <td className="text-center">{oBirth.father}</td>
                  <td className="text-center">{oBirth.mother}</td>
                  <td className="text-center">{oBirth.grandfather}</td>
                  <td className="text-center"><img width={25} src={`${Constants.API_COUNTRIES}${oBirth.country}.png`} alt={`country_${oBirth.country}`} /></td>
                  <td className="text-center">{oBirth.birth}</td>
                </tr>
              ))
              :
              <tr key={`empty_register_per_production`} className="text-center">
                <td colSpan={6}>
                  No hay producción registrada para el año indicado.
                </td>
              </tr>
          }
        </tbody>
      </Table>
    </div>
  );
}
