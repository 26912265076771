import { PRODUCTION_ACTIONS } from 'constants/Actions/Production';

// Estado inicial del reducer
const oInitialState = {
    aProduction: null,
    aHomeProduction: null,
    nTotalPages: 0,
}
/**
 * Reducer que sera utilizado para controlar los datos globales referidos a la producción del sistema.
 */
export const ProductionReducers = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case PRODUCTION_ACTIONS.PRODUCTION:
            return { ...oState, aProduction: oAction.data.data, nTotalPages: oAction.data.pages };
        case PRODUCTION_ACTIONS.PRODUCTION_CLEAN:
                return { ...oState, aProduction: null, nTotalPages: 0 };
        case PRODUCTION_ACTIONS.PRODUCTION_HOME:
            return { ...oState, aHomeProduction: oAction.data };
        default:
            return oState;
    }
}
