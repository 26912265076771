import React, { Component } from 'react';
import Header from 'components/layout/Header';
import Body from 'components/layout/Body';
import RouteConfig from 'router/Router';
import Loading from 'components/Loading';
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from 'react-redux';
import Message from 'components/message/Message';
import Footer from 'components/layout/Footer';

class Layout extends Component {
  render = () => {
    return (
      <>
        <Router basename={'/'}>
          <Header />
          <Body>
            <RouteConfig />
          </Body>
          <Footer />
        </Router>
        <Loading />
        <Message />
      </>
    );
  }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout);