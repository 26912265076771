import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { actionGetNews } from 'redux/actions/NewActions';
import NoticeCard from 'components/notices/NoticeCard';
import PaginationTable from 'components/shared/PaginationTable';

class NewsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nPage: 1,
        };
    }

    componentDidMount = async () => { this.getNews() }

    componentDidUpdate = (oPrevProps) => { }

    getNews = () => {
        const { nPage } = this.state;
        this.props.getNews((nPage - 1));
    }
    
    render = () => {
        const { aNews, nTotalPages } = this.props.oNews;
        const { nPage } = this.state;
        return (
            <div>
                <h2 className='title'>Noticias</h2>
                <hr />
                <Row>
                    {aNews && aNews.map((oNotice, nIndex) => (
                        <Col className="my-3" md={4} key={`${oNotice.id}_${oNotice.title}_${nIndex}_news`}>
                            <NoticeCard oNotice={oNotice}/>
                        </Col>
                    ))}
                </Row>
                <PaginationTable nPage={nPage} nTotalPages={nTotalPages} fAction={this.handleChangePage} sClassName='float-right' />
            </div>
        );
    };
}

const mapStateToProps = oState => ({
    oSession: oState.sessionReducer,
    oNews: oState.NewReducers,
})

const mapDispatchToProps = dispatch => ({
  getNews: (nPage) => {
    dispatch(actionGetNews(nPage));
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);