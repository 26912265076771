import React, { Component } from "react";
import { connect } from "react-redux";
import "pages/public/index/IndexPage.css";
import "assets/css/production.css";
import { ArrowRightShort } from "react-bootstrap-icons";
import ExemplaryCard from "components/exemplaries/ExemplaryCard";
import { Col, Row, Container } from "react-bootstrap";
import { actionGetNewsHome } from "redux/actions/NewActions";
import { actionGetExemplariesPerHome, actionGetExemplary } from "redux/actions/ExemplaryActions";
import { actionGetProductionPerHome, actionGetProductionByYear } from "redux/actions/ProductionActions";
import ProductionTable from "components/production/ProductionTable";
import LayoutBackground from "components/shared/LayoutBackground";
import { Helmet } from "react-helmet";

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0)
    this.props.getExemplaries();
    this.props.getProduction();
  };

  componentDidUpdate = (oPrevProps) => { };

  handleNavigate = (oExemplary) => {
    this.props.history.push(`ejemplar/${oExemplary.id}`);
  };

  getProduction = (nValue) => {
    this.props.getProductionByYear(nValue);
  }

  goToNews = () => {
    this.props.history.push("/noticias");
  }

  goToStallions = () => {
    this.props.history.push("/padrillos");
  }

  goToMares = () => {
    this.props.history.push("/yeguas");
  }

  goToProduction = () => {
    this.props.history.push("/produccion");
  }

  render = () => {
    const { aHomeMares: aMares, aHomeStallions: aStallions } = this.props.oExemplaries;
    const { aHomeProduction } = this.props.oProduction;
    return (
      <>
        <Helmet>
          <title>Home | Haras Santa María de Juncal</title>
          <meta name="description" content="Home del sitio del Haras Santa María de Juncal" />
        </Helmet>
        <LayoutBackground />
        <Container className='content-app'>
          <div className="body-container">
            {aStallions && (
              <>
                <h2 className="title">Padrillos</h2>
                <hr />
                <Row className="mb-3">
                  {aStallions.length > 0 ? (
                    aStallions.map((oStallion, nIndex) => (
                      <Col lg={4} md={4} xs={12} key={`${oStallion.id}_${oStallion.name}_${nIndex}_container`}>
                        <ExemplaryCard handleAction={() => this.handleNavigate(oStallion)} oExemplary={oStallion} nCount={3}/>
                      </Col>
                    ))
                  ) : (
                    <Col md={12} className="p-5 text-center">
                      <p>De momento no contamos con Padrillos en exposición</p>
                    </Col>
                  )}
                </Row>
                {aStallions.length > 0 && (
                  <div className="w-100 text-right">
                    <i className="btn-more p-1 more" onClick={() => this.goToStallions()}>
                      Más padrillos <ArrowRightShort size={26} />
                    </i>
                  </div>
                )}
              </>
            )}
            {aMares && (
              <>
                <h2 className="title">Yeguas Madre</h2>
                <hr />
                <Row className="mb-3">
                  {aMares.length > 0 ? (
                    aMares.map((oMare, nIndex) => (
                      <Col lg={4} md={6} xs={12} key={`${oMare.id}_${oMare.name}_${nIndex}_container`}>
                        <ExemplaryCard handleAction={() => this.handleNavigate(oMare)} oExemplary={oMare} nCount={3}/>
                      </Col>
                    ))
                  ) : (
                    <Col md={12} className="p-5 text-center">
                      <p>De momento no contamos con Yeguas Madre en exposición</p>
                    </Col>
                  )}
                </Row>
                {aMares.length > 0 && (
                  <div className="w-100 text-right">
                    <i className="btn-more p-1 more" onClick={() => this.goToMares()}>
                      Más Yeguas <ArrowRightShort size={26} />
                    </i>
                  </div>
                )}
              </>
            )}
            {
              aHomeProduction && (
                <>
                  <h2 className="title">Producción</h2>
                  <hr />
                  <ProductionTable aProduction={aHomeProduction} />
                  <div className="w-100 text-right">
                    <i className="btn-more p-1 more" onClick={() => this.goToProduction()}>
                      Histórico de Producción <ArrowRightShort size={26} />
                    </i>
                  </div>
                </>
              )}
          </div>
        </Container>
      </>
    );
  };
}

const mapStateToProps = (oState) => ({
  oNews: oState.NewReducers,
  oExemplaries: oState.ExemplaryReducers,
  oProduction: oState.ProductionReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getNotices: () => {
    dispatch(actionGetNewsHome());
  },
  getExemplaries: () => {
    dispatch(actionGetExemplariesPerHome());
  },
  getProduction: () => {
    dispatch(actionGetProductionPerHome());
  },
  getProductionByYear: (nYear) => {
    dispatch(actionGetProductionByYear(nYear));
  },
  getExemplary: (id) => {
    dispatch(actionGetExemplary(id))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);
