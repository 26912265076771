import React from 'react';
import { connect } from 'react-redux';

function Body(props) {
  return (
    <div className='body'>
      {props.children}
    </div>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Body);
