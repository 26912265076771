import React from 'react';
import 'assets/css/notices.css';

export default function NoticeCard(oProps) {

  const {title: sTitle, description: sDescription} = oProps.oNotice;

  let flip = () => {
    const cards = document.querySelectorAll('.card_inner')
    cards.forEach((oCard, nIndex) => {
      oCard.addEventListener('click', function (){
        oCard.classList.toggle('is-flipped');
      });
    });
  }

  return (
    <div className='card' onClick={flip()}>
      <div className='card_inner'>
        <div className='card_face card_face-front'></div>
        <div className='card_face card_face-back'>
          <div className='card_content'>
            <div className='card_header'></div>
            <div className='card_body'>
              <h3>{sTitle}</h3>
              <p>{sDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
