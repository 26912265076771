import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Logo from 'assets/logo.png';
import { List } from 'react-bootstrap-icons';
import 'assets/css/header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => { };

  componentDidUpdate = (oPrevProps) => { };

  navSlide = () => {
    const oNav = document.querySelector('.header-nav-ul');
    const oNavLinks = document.querySelectorAll('.header-nav-ul li');
    oNav.classList.toggle('header-nav-ul-active');
    oNavLinks.forEach((oLink, nIndex) => {
      if (!oLink.style.animation)
        oLink.style.animation = `navLinkFade 1s ease forwards ${nIndex / 6.5}s`;
    });
  }

  navToggle = () => {
    const oNav = document.querySelector('.header-nav-ul-active');
    if(oNav)
      oNav.classList.toggle('header-nav-ul-active');
  }

  render = () => {
    return (
      <header className='header shadow bg-white rounded navbar-fixed-top container-fluid px-md-5 py-2'>
        <div className='row header-contenedor'>
          <div className='header-logo'>
            <a href='/'>
              <img src={Logo} width='80' alt='Logo' />
            </a>
          </div>
          <nav>
            <ul className='header-nav-ul'>
              {/* <li>
                <NavLink className='item-menu' to='/noticias' exact activeClassName='item-menu-active'>
                  Noticias
                </NavLink>
              </li> */}
              <li>
                <NavLink onClick={this.navToggle} className='item-menu' to='/padrillos' activeClassName='item-menu-active' >
                  Padrillos
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.navToggle} className='item-menu' to='/yeguas' activeClassName='item-menu-active' >
                  Yeguas Madre
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.navToggle} className='item-menu' to='/produccion' activeClassName='item-menu-active' >
                  Producción
                </NavLink>
              </li>
              <li>
                <NavLink onClick={this.navToggle} className='item-menu' to='/haras' activeClassName='item-menu-active' >
                  Haras
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className='header-burguer'>
            <List size={26} onClick={this.navSlide} />
          </div>
        </div>
      </header>
    );
  };
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
