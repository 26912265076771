import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductionTable from 'components/production/ProductionTable';
import { actionCleanProduction, actionGetProductionByYear } from 'redux/actions/ProductionActions';
import 'assets/css/production.css';
import { Button, Row, Container, Col } from 'react-bootstrap';
import PaginationTable from 'components/shared/PaginationTable';
import { Helmet } from "react-helmet";
import Search from 'components/shared/Search';

class ProductionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nPage: 1,
      nYear: 2022,
      aYears: [
        { name: '2009', value: 2009 },
        { name: '2010', value: 2010 },
        { name: '2011', value: 2011 },
        { name: '2012', value: 2012 },
        { name: '2013', value: 2013 },
        { name: '2014', value: 2014 },
        { name: '2015', value: 2015 },
        { name: '2016', value: 2016 },
        { name: '2017', value: 2017 },
        { name: '2018', value: 2018 },
        { name: '2019', value: 2019 },
        { name: '2020', value: 2020 },
        { name: '2021', value: 2021 },
        { name: '2022', value: 2022 }
      ],
      search: ""
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.props.getProduction(2022,this.state.nPage - 1, this.state.search);
  };

  componentDidUpdate = (oPrevProps) => { };

  componentWillUnmount = async () => {
    this.props.cleanProduction();
  }

  getProduction = () => {
    const { nYear, nPage, search } = this.state;
    this.props.getProduction(nYear, (nPage - 1), search);
  }

  handleChangeYear = async (nYear) => {
    await this.setState({ nYear, nPage: 1 });
    this.getProduction();
  }

  handleChangePage = async (nPage) => {
    await this.setState({ nPage });
    this.getProduction();
  }

  handleSearch = async (search) => {
    const { nYear } = this.state;
    this.props.getProduction(nYear, 0, search);
    this.setState({ search, nPage: 1 });
  }

  render = () => {
    const { aProduction, nTotalPages } = this.props.oProduction;
    const { aYears, nYear, nPage } = this.state;
    return (
      <Container>
        <Helmet>
          <title>Producción | Haras Santa María de Juncal</title>
          <meta name="description" content="Producción del Haras Santa María de Juncal" />
        </Helmet>
        <Row className='container-title'>
          <Col md={6} sm={6} xs={12}>
          <h2 className='title'>Producción</h2>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <Search action={this.handleSearch} />
          </Col>
        </Row>
        <hr />
        <div className='buttons_years'>
          {
            aYears && aYears.map((oYear, index) => {
              return <Button className={nYear === oYear.value ? 'active' : ''} value={oYear.value} key={oYear.value + index} onClick={() => this.handleChangeYear(oYear.value)}>{oYear.name}</Button>
            })
          }
        </div>
        <ProductionTable aProduction={aProduction} />
        <Row className="justify-center">
          <PaginationTable nPage={nPage} nTotalPages={nTotalPages} fAction={this.handleChangePage} />
        </Row>
      </Container>
    );
  };
}

const mapStateToProps = (oState) => ({
  oSession: oState.sessionReducer,
  oProduction: oState.ProductionReducers,
});

const mapDispatchToProps = (dispatch) => ({
  getProduction: (nYear, nPage, search) => {
    dispatch(actionGetProductionByYear(nYear, nPage, search));
  },
  cleanProduction: () => {
    dispatch(actionCleanProduction());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionPage);
