import { SHARED_ACTIONS } from '../../constants/Actions/Shared';
import { CONSTANTS_MESSAGE } from '../../constants/ConstantsMessages';
import { DATES_FORMATS } from '../../constants/DataTypes';

var oMoment = require('moment');

/**
 * Funcion que realiza un dispatch global
 */
export const actionDispatch = (sTypeAction, oData = null) => ({
    type: sTypeAction,
    data: oData
});

/**
 * Funcion que activa el mensaje Toast
 */
export const actionMessage = (sMessage = CONSTANTS_MESSAGE.DEFAULT_MESSAGE_ERROR, sMessageType = CONSTANTS_MESSAGE.TYPE_ERROR) => ({
    type: SHARED_ACTIONS.MESSAGE,
    sMessage: sMessage,
    sMessageType: sMessageType
})

/**
 * Funcion que activa el mensaje Toast
 */
export const actionCleanMessage = () => {
    return dispatch => {
        dispatch(actionDispatch(SHARED_ACTIONS.CLEAN_MESSAGE));
    }
}

/**
 * Funcion que activa el loading
 */
export const actionActiveLoading = () => {
    return dispatch => {
        dispatch(actionDispatch(SHARED_ACTIONS.ACTIVE_LOADING, null));
    }
};

/**
 * Funcion que desactiva el loading
 */
export const actionDesactiveLoading = () => {
    return dispatch => {
        dispatch(actionDispatch(SHARED_ACTIONS.DESACTIVE_LOADING, null));
    }
};

/**
 * Funcion que permite desde la vista enviar un mensaje
 */
export const actionSendMessage = (sMessage) => {
    return dispatch => {
        dispatch(actionMessage(sMessage));
    }
}

/**
 * Funcion que permite castear un booleano o 0 1 a string para la vista
 */
export const CastBool = (nBool) => {
    let sValue = 'S/D';
    switch (nBool) {
        case 1:
        case true:
            sValue = 'Sí';
            break;
        case 0:
        case false:
        default:
            sValue = 'No';
            break;
    }
    return sValue;
}

/**
 * Funcion que le da un formato a todas las fechas del sistema, centraliza el uso de moment y el formato de las fechas
 * Casteo de fechas del tipo Date
 */
export const CastDate = (sDate, sFormat = DATES_FORMATS.DEFAULT) => {
    return oMoment(sDate).format(sFormat);
}

/**
 * Funcion que le da un formato a todas las fechas del sistema, centraliza el uso de moment y el formato de las fechas
 * Casteo de fechas del tipo Timestamp
 */
export const CastDateTime = (sDate, sFormat = DATES_FORMATS.TIMESTAPMS_DAFAULT) => {
    return oMoment(sDate).format(sFormat);
}