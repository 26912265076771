const APP_TOKEN_STORAGE = '@harassantamariadejuncal';

/**
 * Funcion que almacena en el local storage el token de autorizacion para tomar los datos del API.
 */
export const setTokenLocalStorange = async (sToken) => {
    try {
        await localStorage.removeItem(APP_TOKEN_STORAGE);
        await localStorage.setItem(APP_TOKEN_STORAGE, sToken);
    } catch (oError) {
        throw oError;
    }
}

/**
 * Funcion que limpia el valor del token de autorizacion del local storage.
 */
export const setEmptyTokenLocalStorange = async () => {
    await localStorage.removeItem(APP_TOKEN_STORAGE);
}

/**
 * Funcion que toma del local storage el token de autorizacion.
 */
export const getTokenLocalStorange = async () => {
    return await localStorage.getItem(APP_TOKEN_STORAGE);
}