import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Container } from 'react-bootstrap';
import { actionCleanExemplary, actionGetExemplary } from 'redux/actions/ExemplaryActions';
import "pages/public/exemplary/ExemplaryPage.css";
import { Constants } from "constants/Constants";
import { Helmet } from "react-helmet";

class ExemplaryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => { 
    window.scrollTo(0, 0)
    this.props.getExemplary(this.props.match.params.nId)
  }

  componentWillUnmount = async () => {
    this.props.cleanExemplary();
  }

  componentDidUpdate = (oPrevProps) => {}

  render = () => {
    const { oExemplary } = this.props.oExemplaries;
    return (
      oExemplary && (
        <Container>
          <Helmet>
            <title>{oExemplary.name} | Haras Santa María de Juncal</title>
            <meta name="description" content={`Información del ejemplar ${oExemplary.name}`} />
          </Helmet>
          <div className='title'>
            <Row>
              <Col md={12}>
                <h1>{oExemplary.name}</h1>
              </Col>
              <Col md={12}><hr /></Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <img src={oExemplary.image ? oExemplary.image : Constants.API_EXEMPLARIES_DEFAULT} alt='Imagen caballo' className='w-100 border-radius-5' />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Row className='data'>
                  <span className='text-bold col-6 p-0'>Padre</span>
                  <span className='col-6 p-0 text-right'>{oExemplary.father}</span>
                  <Col md={12}><hr /></Col>
                  <span className='text-bold col-6 p-0'>Madre</span>
                  <span className='col-6 p-0 text-right'>{oExemplary.mother}</span>
                  <Col md={12}><hr /></Col>
                  <span className='text-bold col-6 p-0'>Abuelo Materno</span>
                  <span className='col-6 p-0 text-right'>{oExemplary.grandfather}</span>
                  <Col md={12}><hr /></Col>
                  <span className='text-bold col-6 p-0'>Pelaje</span>
                  <span className='col-6 p-0 text-right'>{oExemplary.fur}</span>
                  <Col md={12}><hr /></Col>
                  <span className='text-bold col-6 p-0'>Generación</span>
                  <span className='col-6 p-0 text-right'>{oExemplary.birth}</span>
                  <Col md={12}><hr /></Col>
                  <span className='text-bold col-6 p-0'>País</span>
                  <span className='col-6 p-0 text-right'><img width={25} src={`${Constants.API_COUNTRIES}${oExemplary.country}.png`} alt={`country_${oExemplary.country}`}/></span>
                  <Col md={12}><hr /></Col>
                  <span className='text-bold col-6 p-0'>Descripción</span>
                  <span className='col-6 p-0 text-right'>{oExemplary.description}</span>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      )
    );
  }
}

const mapStateToProps = state => ({
  oSession: state.sessionReducer,
  oExemplaries: state.ExemplaryReducers,
})

const mapDispatchToProps = dispatch => ({
  getExemplary: (id) => {
    dispatch(actionGetExemplary(id));
  },
  cleanExemplary: () => {
    dispatch(actionCleanExemplary());
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ExemplaryPage);