import React, {useState, useCallback} from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoBox } from '@react-google-maps/api';
import { Constants } from 'constants/Constants';
import 'assets/css/map.css'

const containerStyle = {
  width: '100%',
  height: '100%',
  padding: '0 !important'
};

const options = {
    center: {
        lat: -34.386272065228084,
        lng: -56.77720314043174
    },
    zoom: 13
};

export default function Map() {

  const { isLoaded } = useJsApiLoader({
    id: Constants.API_GOOGLE_MAP_ID,
    googleMapsApiKey: Constants.API_GOOGLE_MAP_API_KEY
  });

  const [map, setMap] = useState(null)
  console.log(map);

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap mapContainerStyle={containerStyle} options={options} onLoad={onLoad} onUnmount={onUnmount}>
        <Marker position={options.center} />
        <InfoBox options={options} position={options.center}>
            <div className='info-map'>
                Haras Santa María de Juncal
            </div>
        </InfoBox>
      </GoogleMap>
  ) : <></>
}