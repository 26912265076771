import { PRODUCTION_ACTIONS } from 'constants/Actions/Production';
import { GetHome, GetProductionByYear } from 'redux/services/ProductionServices';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage } from './SharedActions';

export const actionGetProductionPerHome = () => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetHome().then(oSuccess => {
            dispatch(actionDispatch(PRODUCTION_ACTIONS.PRODUCTION_HOME, oSuccess.data));
        }).catch((oError) => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

export const actionGetProductionByYear = (nYear, nPage, search) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetProductionByYear(nYear, nPage, search).then(oSuccess => {
            dispatch(actionDispatch(PRODUCTION_ACTIONS.PRODUCTION, oSuccess));
        }).catch((oError) => {
            dispatch(actionMessage(oError));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

export const actionCleanProduction = () => {
    return dispatch => dispatch(actionDispatch(PRODUCTION_ACTIONS.PRODUCTION_CLEAN));
}