import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import 'assets/css/404.css';
import { Helmet } from "react-helmet";

export default function NotFound({ title = 404, message = "Recurso no encontrado" }) {
  return (
    <Container>
      <Helmet>
          <title>404 | Haras Santa María de Juncal</title>
          <meta name="description" content="No se encontró el recurso solicitado en Haras Santa María de Juncal" />
      </Helmet>
      <Row className='error_section'>
        <Col md={12}>
          <h1>{title}</h1>
        </Col>
        <Col md={12}>
          <h6>{message}</h6>
        </Col>
      </Row>
    </Container>
  );
}
