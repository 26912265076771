import React from 'react';
import Haras from 'assets/img/haras_santa_maria.png';
import "pages/public/index/IndexPage.css";
  
export default function LayoutBackground() {

    return (
        <div className="cover-container my-6 my-md-5" >
            <img src={Haras} alt="Portada del sitio"/>
        </div>
    );
}
