import { NEW_ACTIONS } from 'constants/Actions/New';

// Estado inicial del reducer
const oInitialState = {
    aNews: null,
    aHomeNews: null,
    nTotalPages: 0
}
/**
 * Reducer que sera utilizado para controlar los datos globales referidos a las noticias.
 */
export const NewReducers = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case NEW_ACTIONS.NEWS_HOME:
            return { ...oState, aHomeNews: oAction.data };
        case NEW_ACTIONS.NEWS:
            return { ...oState, aNews: oAction.data, nTotalPages: oAction.pages }
        default:
            return oState;
    }
}
