import 'App.css';
import { Provider } from 'react-redux';
import store, { persistor } from 'redux/Store';
import Layout from 'pages/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <Layout />
      </PersistGate>
    </Provider>
  );
}

export default App;
