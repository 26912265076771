import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EnvelopeFill, GeoAltFill, TelephoneFill, Instagram, Facebook } from 'react-bootstrap-icons';
import 'assets/css/footer.css';
import { Col, Form, Row } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { actionSendMessage } from 'redux/actions/SharedActions';
import { actionCleanContact, actionSendContact } from 'redux/actions/ContactActions';

const oInitialState = {
  sCaptcha: null,
  sName: '',
  sEmail: '',
  sComment: '',
};

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = oInitialState;
  }

  componentDidMount = async () => { }

  componentDidUpdate = (oPrevProps) => {
    if (this.props.oContactProps.bStatus) {
      this.setState(oInitialState);
      this.props.clean();
    }
  }

  onChangeCaptcha = (sValue) => {
    this.setState({ sCaptcha: sValue });
  }

  handleChangeValue = (oEvent) => {
    this.setState({ [oEvent.target.id]: oEvent.target.value });
  }

  onAction = () => {
    let { sName, sEmail, sComment, sCaptcha } = this.state;
    sName = sName.trim();
    sEmail = sEmail.trim();
    sComment = sComment.trim();
    let sMessageError = this.validate(sName, sEmail, sComment, sCaptcha);
    if (sMessageError)
      return this.props.message(sMessageError);
    this.props.send(sName, sEmail, sComment);
  }

  validate = (sName, sEmail, sComment, sCaptcha) => {
    if (!sName)
      return 'Su nombre es obligatorio';
    if (!sEmail)
      return 'Su email es obligatorio';
    if (!sComment)
      return 'Ingrese un comentario que desee enviarnos';
    if (sComment.length < 10)
      return 'Su comentario es demasiado corto';
    if (!sCaptcha)
      return 'Verifique la casilla reCAPTCHA';
    return null;
  }

  render = () => {
    const { sName, sEmail, sComment, } = this.state;
    return (
      <footer className='mt-5'>
        <Row className='m-0 p-0 pt-4 pb-3 px-4'>
          <Col md={12}>
            <h2>Contacto</h2>
          </Col>
          <Col md={4}>
            <div className='mb-2 social-icons'>
              <EnvelopeFill size={18} color='var(--text-white-color)' />
              <span className='ml-2'>
                santamariadejuncal@gmail.com
              </span>
            </div>
            <div className='mb-2 social-icons'>
              <GeoAltFill size={18} color='var(--text-white-color)' />
              <span className='ml-2'>
                Ruta 3 Km 87, San José, Uruguay
              </span>
            </div>
            <div className='mb-2 social-icons'>
              <TelephoneFill size={18} color='var(--text-white-color)' />
              <span className='ml-2'>
                (+598) 099 282 486
              </span>
            </div>
            <div className='mb-2 social-icons'>
              <Facebook size={18} color='var(--text-white-color)' />
              <span className='ml-2'><a href='https://www.facebook.com/harassantamariadejuncal'>Haras Santa María de Juncal</a></span>
            </div>
            <div className='mb-2 social-icons'>
              <Instagram size={18} color='var(--text-white-color)' />
              <span className='ml-2'><a href='https://www.instagram.com/harassantamariadejuncal/'>harassantamariadejuncal</a></span>
            </div>
          </Col>
          <Col md={4}></Col>
          <Col md={4}>
            <h5 className='m-contact'>Consúltenos</h5>
            <Row className='m-0 p-0'>
              <div className='col-6 m-0 p-0 pr-1'>
                <Form.Control id='sName' type="text" placeholder="Nombre" required
                  value={sName} onChange={this.handleChangeValue} />
              </div>
              <div className='col-6 m-0 p-0 pl-1'>
                <Form.Control id='sEmail' type="text" placeholder="E-mail" required
                  value={sEmail} onChange={this.handleChangeValue} />
              </div>
              <div className='col-12 m-0 p-0 mt-2'>
                <Form.Control id='sComment' type='text' placeholder='Consulta' rows={6} as='textarea'
                  value={sComment} onChange={this.handleChangeValue} />
              </div>
              <div className="col-12 m-0 p-0 mt-2 text-right">
                <Row className='m-0'>
                  <div className='col-12 col-md-8 p-0 m-0'>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} onChange={this.onChangeCaptcha} />
                  </div>
                  <div className='col-12 col-md-6 col-lg-4 p-0 m-0'>
                    <button className="btn btn-footer-contact" onClick={this.onAction}>Enviar</button>
                  </div>
                </Row>
              </div>
            </Row>
          </Col>
          <Col md={6} className='text-left mt-3'></Col>
          <Col md={6} className='text-right mt-3'>
            <small>© 2022 <b>Haras Santa María de Juncal</b></small>
          </Col>
        </Row>
      </footer>
    );
  }
}

const mapStateToProps = oState => ({
  oContactProps: oState.ContactReducers,
});

const mapDispatchToProps = dispatch => ({
  message: (sMessage) => {
    dispatch(actionSendMessage(sMessage));
  },
  send: (sName, sEmail, sComment) => {
    dispatch(actionSendContact(sName, sEmail, sComment));
  },
  clean: () => {
    dispatch(actionCleanContact());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);