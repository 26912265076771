import React from "react";
import { Row, Col } from "react-bootstrap";
import "assets/css/exemplary-card.css";
import { Constants } from "constants/Constants";

export default function ExemplaryCard(oProps) {
  const { oExemplary, handleAction } = oProps;
  const { name: sName, image: sImage, father: sFather, mother: sMother, grandfather: sGrandFather, birth: nBirthdate, country: sCountry  } = oExemplary;

  return (
    <article onClick={handleAction}>
      <div className="card">
        <img src={sImage} alt=""/>
        <h4>{sName}</h4>
        <hr className="hr_card"/>
        <div>
          <Row>
            <Col className="subtitle">Padre: </Col>
            <Col className="text-center">{sFather && sFather.length > 12 ? `${sFather.substr(0,11)}...` : sFather}</Col>
          </Row>
          <Row>
            <Col className="subtitle">Madre: </Col>
            <Col className="text-center">{sMother && sMother.length > 12 ? `${sMother.substr(0,11)}...` : sMother}</Col>
          </Row>
          <Row>
            <Col className="subtitle">A Materno: </Col>
            <Col className="text-center">{sGrandFather && sGrandFather.length > 12 ? `${sGrandFather.substr(0,11)}...` : sGrandFather}</Col>
          </Row>
          <Row>
            <Col className="subtitle">Generación: </Col>
            <Col className="text-center">{nBirthdate}</Col>
          </Row>
          <Row>
            <Col className="subtitle">País:</Col>
            <Col className="text-center"><img className="country_icon" width={"10px"} src={`${Constants.API_COUNTRIES}${sCountry}.png`} alt=""/></Col>
          </Row>
        </div>
      </div>
    </article>
  );
}