import { EXEMPLARY_ACTIONS } from 'constants/Actions/Exemplary';

// Estado inicial del reducer
const oInitialState = {
    aHomeStallions: null,
    aHomeMares: null,
    oExemplary: null,
    aStallions: null,
    aMares: null,
    nTotalPages: null
}

export const ExemplaryReducers = (oState = oInitialState, oAction) => {
    switch (oAction.type) {
        case EXEMPLARY_ACTIONS.EXEMPLARIES_HOME:
            return { ...oState, aHomeStallions: oAction.data.stallions, aHomeMares: oAction.data.mares };
        case EXEMPLARY_ACTIONS.STALLIONS:
            return { ...oState, aStallions: oAction.data.data, nTotalPages: oAction.data.pages }
        case EXEMPLARY_ACTIONS.STALLIONS_CLEAN:
            return { ...oState, aStallions: null }
        case EXEMPLARY_ACTIONS.MARES:
                return { ...oState, aMares: oAction.data.data, nTotalPages: oAction.data.pages }
        case EXEMPLARY_ACTIONS.MARES_CLEAN:
                return { ...oState, aMares: null }
        case EXEMPLARY_ACTIONS.EXEMPLARY:
            return { ...oState, oExemplary: oAction.data};
        case EXEMPLARY_ACTIONS.EXEMPLARY_CLEAN:
                return { ...oState, oExemplary: null};
        default:
            return oState;
    }
}
