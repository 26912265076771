import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { SharedReducer } from 'redux/reducers/SharedReducers';
import { ContactReducers } from 'redux/reducers/ContactReducers';
import { NewReducers } from 'redux/reducers/NewReducers';
import { ExemplaryReducers } from 'redux/reducers/ExemplaryReducers';
import { ProductionReducers } from 'redux/reducers/ProductionReducers';

// Requerimineto para auto refresh de cache
const oMeta = require('meta.json');

const reducers = combineReducers({
    SharedReducer,
    ContactReducers,
    NewReducers,
    ExemplaryReducers,
    ProductionReducers,
});


const persistConfig = {
    key: oMeta.hash,
    version: oMeta.version,
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)
const store = createStore(persistedReducer, applyMiddleware(thunk));

export default store;
export const persistor = persistStore(store);