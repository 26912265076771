import React, { useState } from 'react';
import { BsSearch } from "react-icons/bs";

function Search(props) {
    const [ search, setSearch ] = useState("");
    const [ timeOut, setTimeOut ] = useState(null);
    const { action } = props;

    const onChangeSearch = (event) => {
        setSearch(event.target.value);
        clearTimeout(timeOut);
        setTimeOut(setTimeout(() => {
            action(event.target.value);
        }, 800)); 
    }

    return (
        <div className='search-container'>
            <BsSearch  size={16}/><input type='text' value={search} onChange={onChangeSearch} placeholder="Buscar..." /> 
        </div>
    )

}

export default Search;