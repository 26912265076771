import { Constants } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";

const URL_PUBLIC_CONTACT = Constants.API_PUBLIC_URL + 'contact/';

/**
 * Servicio que ingresa una nueva consulta al sistema
 */
export const SendContact = (sName, sEmail, sComment) => {
    return oAxiosInstance.post(URL_PUBLIC_CONTACT, { name: sName, email: sEmail, comment: sComment }).then(oResult => oResult.data);
}