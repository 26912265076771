import React from 'react';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

function Loading(props) {
  return (
    props.oShared.bLoading ?
      <div className="spinner_loading">
        <Spinner animation="border" role="status"/>
      </div > : null
  )
}

const mapStateToProps = state => ({
  oShared: state.SharedReducer,
})

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
