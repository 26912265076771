import { NEW_ACTIONS } from 'constants/Actions/New';
import { GetNewsHome, GetNews } from 'redux/services/NewServices';
import { actionActiveLoading, actionDesactiveLoading, actionDispatch, actionMessage } from './SharedActions';

export const actionGetNewsHome = () => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetNewsHome().then(oSuccess => {
            dispatch(actionDispatch(NEW_ACTIONS.NEWS_HOME, oSuccess.data));
        }).catch((oError) => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}

 export const actionGetNews = (nPage = 1) => {
    return dispatch => {
        dispatch(actionActiveLoading());
        GetNews(nPage).then(oSuccess => {
            dispatch(actionDispatch(NEW_ACTIONS.NEWS, oSuccess.data));
        }).catch((oError) => {
            let sMessage = oError.data?.message ?? null;
            dispatch(actionMessage(sMessage));
        }).finally(() => {
            dispatch(actionDesactiveLoading());
        });
    }
}