import { Constants } from "constants/Constants";
import oAxiosInstance from "redux/interceptors/Interceptors";

const URL_PUBLIC_PRODUCTION = Constants.API_PUBLIC_URL + 'production/';

/**
 * Servicio que toma la producción que será mostrada en la vista del Home
 */
export const GetHome = () => {
    return oAxiosInstance.get(`${URL_PUBLIC_PRODUCTION}`).then(oResult => oResult.data);
}

/**
 * Servicio que toma la producción anual de forma paginada
 */
export const GetProductionByYear = (nYear, nPage, search) => {
    return oAxiosInstance.post(`${URL_PUBLIC_PRODUCTION}${nYear}`, { page: nPage, search: search }).then(oResult => oResult.data);
}